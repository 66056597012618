<template>
	<div class="index">
		<div class="chat-list">
			<div class="nav-title">私信</div>
			<spider-search @searchTransmit="searchTransmit" @searchInput="searchInput" />
			<div class="chat-list-user">
				<div v-for="(item, index) in dataList" :key="index" @click="lookChatRecords(item)"
					:class="current == index ? 'active-background' : 'user-info'">
					<div class="user-icon" v-if="item.infoData">
						<img :src="item.infoData.avatar" v-if="item.infoData.avatar" />
						<img src="@/assets/images/logo@3x.png" v-else />
						<span v-if="item.unreadMessageCount > 0" :class="item.unreadMessageCount < 10 ? 'badge' : 'badge2'">
							{{ item.unreadMessageCount }}
						</span>
					</div>
					<div class="user-right">
						<div>
							<p class="user_name" v-if="item.infoData">
								{{ item.infoData.nickName || '暂无' }}
							</p>
							<p v-if="new Date(item.endTime).getDate() === new Date().getDate()">
								{{ formateDate(item.endTime,"HH:mm") }}
							</p>
							<!-- // 这里把时间戳转换为`日`，然后跟当前的`日`进行比较，如果相等，则说明是今天的时间戳，则显示`time` -->
							<p v-else-if="
                  new Date(item.endTime).getDate() === new Date().getDate() - 1
                ">昨天</p>
							<!-- // 这里把**当前**的时间戳 `-1`，说明是昨天。比如说今天是18日，`-1`之后就是17，如果`time`转换后 与 17 相等，说明应该显示为`昨天`。 -->
							<p v-else-if="
                  new Date(item.endTime).getDate() === new Date().getDate() - 2
                ">前天</p>
							<p v-else>{{ formateDate(item.endTime, "MM/dd") }}</p>
						</div>
						<p style="font-size: 12px; color: #7b7d80" class="msg-box">
							{{ item.latestMessage | messageFormatting }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isShow" class="default">
			<img src="@/assets/images/logo@3x.png" />
		</div>
		<!-- 聊天框 -->
		<spider-window-messagebox ref="SpiderWindowMessagebox" v-else />
	</div>
</template>

<script>
	import spiderSearch from "../../../components/spider-search/spider-search.vue";
	import SpiderWindowMessagebox from "../../../components/spider-window-message/spider-window-messagebox.vue";
	import {
		mapState
	} from "vuex";

	export default {
		components: {
			spiderSearch,
			SpiderWindowMessagebox
		},
		data() {
			return {
				dataList: [],
				current: null,
				isShow: true,
				chatList: [],
				total: 0,
			};
		},
		computed: {
			...mapState({
				msgArr: (state) => state.rongyun.sendMsgArr,
				updateList: (state) => state.rongyun.updateList, //更新列表
				//获取到vuex融云实例化的数据
				RongIMClient: (state) => state.rongyun.RongIMClient, //融云实例
				upDateMessageNum: (state) => state.updateNum.upDateMessageNum, //私信的数量
				messageList: (state) => state.rongyun.messageList, //消息列表
				msgUpdateStatus: (state) => state.rongyun.msgUpdateStatus, //通知消息列表更新
			}),
		},
		watch: {
			msgArr(newVal, oldVal) {
				oldVal = newVal;
			},
			//监听融云实例
			RongIMClient(newVal, oldVal) {
				oldVal = newVal;
			},
			//监听消息列表
			messageList(newVal, oldVal) {
				oldVal = newVal;
			},
			//监听消息列表未读的数量
			upDateMessageNum(newVal, oldVal) {
				oldVal = newVal;
				setTimeout(() => {
					this.getMessageList();
				}, 100);
			},
			//更新聊天列表
			updateList(newVal, oldVal) {
				oldVal = newVal;
				// this.getMessageList()
				setTimeout(() => {
					this.getMessageList();
				}, 100);
			},
		},
		filters: {
			//信息类型格式化
			messageFormatting(val) {
				switch (val.messageType) {
					case 'RC:TxtMsg':
						return val.content.content
					case 'RC:RcCmd':
						return '[]'
					case 'RC:VcMsg':
						return '[语音]'
					case 'RC:ImgMsg':
						return '[图片]'
					case 'RC:StkImgMsg':
						return '[表情]'
					case 'RC:SightMsg':
						return '[视频]'
					case 'RC:CombineMsg':
						return '[聊天记录]'
					case 'RC:SCCardMsg':
						return val.content.is_CardType ? '[卡片]' : '[名片]'
					case 'RC:FileMsg':
						return '[文件]'
					case 'RC:GroupInviteMsg':
						return '[群聊邀请]'
					case 'RC:InvitationMsg':
						return '[群聊邀请]'
					case 'RC:LBSMsg':
						return '[位置]'
					case 'RC:RecordSpeechMsg':
						return '[录音讲话]'
					case 'RC:ArticleMsg':
						return '[文章]'
					case 'RC:SpiderDramaMsg':
						return '[蜘影剧]'
					case 'RC:GiftMsg':
						return '[礼物]'
					default:
						return '[' + val.messageType + ']'
				}
			},
			//日期格式化
			formatDate(date, fmt) {
				date = new Date(date);
				if (typeof fmt === "undefined") {
					fmt = "yyyy-MM-dd HH:mm:ss";
				}
				if (/(y+)/.test(fmt)) {
					fmt = fmt.replace(
						RegExp.$1,
						(date.getFullYear() + "").substr(4 - RegExp.$1.length)
					);
				}
				let o = {
					Y: date.getFullYear(),
					"M+": date.getMonth() + 1,
					"d+": date.getDate(),
					"H+": date.getHours(),
					"m+": date.getMinutes(),
					"s+": date.getSeconds(),
				};
				for (let k in o) {
					if (new RegExp(`(${k})`).test(fmt)) {
						let str = o[k] + "";
						fmt = fmt.replace(
							RegExp.$1,
							RegExp.$1.length === 1 ? str : ("00" + str).substr(str.length)
						);
					}
				}
				return fmt;
			},
		},
		created() {
			// this.watchM()
			setTimeout(() => {
				this.getMessageList(); //获取消息列表
				// this.getNoReadNum() //获取全部会话未读数 私聊的
				// this.$store.dispatch('getMessageList',this)
				if (localStorage.getItem("chatRecords")) {
					this.lookChatRecords(JSON.parse(localStorage.getItem("chatRecords")));
				}
			}, 1000);
		},
		methods: {
			//日期格式化
			formateDate(date, fmt) {
				date = new Date(date);
				if (typeof fmt === "undefined") {
					fmt = "yyyy-MM-dd HH:mm:ss";
				}
				if (/(y+)/.test(fmt)) {
					fmt = fmt.replace(
						RegExp.$1,
						(date.getFullYear() + "").substr(4 - RegExp.$1.length)
					);
				}
				let o = {
					Y: date.getFullYear(),
					"M+": date.getMonth() + 1,
					"d+": date.getDate(),
					"H+": date.getHours(),
					"m+": date.getMinutes(),
					"s+": date.getSeconds(),
				};
				for (let k in o) {
					if (new RegExp(`(${k})`).test(fmt)) {
						let str = o[k] + "";
						fmt = fmt.replace(
							RegExp.$1,
							RegExp.$1.length === 1 ? str : ("00" + str).substr(str.length)
						);
					}
				}
				return fmt;
			},
			//搜索框的搜索
			searchTransmit(val) {
				let arr = [];
				let copyList = [...this.dataList];
				copyList.forEach((item) => {
					if (item.nickName.indexOf(val) > -1) {
						console.log(item);
						arr.push(item);
					}
				});
				this.dataList = [...arr];
			},
			//搜索框搜索
			searchInput(e) {
				if (e == "") {
					this.getMessageList();
				}
				//  else{
				//    this.searchTransmit(e)
				//  }
			},
			//获取消息列表
			getMessageList() {
				// 开始时间
				const startTime = 0
				const count = 1000
				this.RongIMClient.Tag.get('0').then(tag => {
					//console.log('标签', tag);
					tag.getConversationList(startTime, count).then(result => {
						// 获取会话列表
						if (!result.code) {
							console.log('标签会话', result);
							let arr = [];
							result.forEach((item) => {
								if (item.conversationType === 1) {
									item.nickName = "";
									arr.unshift(item);
								}
							});
							this.dataList = arr;
							this.dataList.forEach((item) => {
								this.$api.userMsg.userDetail(item.targetId).then((res) => {
									if (res.result) {
										this.$set(item, "infoData", res.result);
										this.$set(item, "nickName", res.result.nickName);
										this.$set(
											item,
											"endTime",
											this.formattingTime(item.latestMessage.sentTime)
										);
									}
								}).catch((err) => {
									this.$message.error(err.message);
								});
							});
						}
					});
				});
			},
			formatDate2(date, vm) {
				//当前时间格式化处理
				let str = "";
				if (new Date(date).getDate() === new Date().getDate()) {
					let date = new Date(date);
					let hour = date.getHours;
					let minute = date.getMinutes();
					hour = hour > 9 ? hour : "0" + 9;
					minute = minute > 9 ? minute : "0" + 9;
					str = hour + ":" + minute;
				} else if (new Date(date).getDate() === new Date().getDate() - 1) {
					str = "昨天";
				} else if (new Date(date).getDate() === new Date().getDate() + 1) {
					str = "(明日)";
				}
				if (new Date(date).getDate() < new Date().getDate() - 1) {
					let date = new Date(date);
					let month = date.getMonth() + 1;
					let day = date.getDate();
					month = month > 9 ? month : "0" + 9;
					day = day > 9 ? day : "0" + 9;
					str = day + "/" + month;
				}
				return str;
			},
			//计算未读数
			computedNoRead(count) {
				this.total += count;
				this.$store.dispatch("changeMSg", {
					num: this.total,
					path: "私信",
				});
			},
			computedListNoRead() {
				this.total = 0;
				this.dataList.forEach((item) => {
					this.computedNoRead(item.unreadMessageCount);
				});
			},
			//获取会话未读数 全部的会话未读
			getNoReadNum() {
				const conversationTypes = [RongIMLib.CONVERSATION_TYPE.PRIVATE];
				this.RongIMClient.Conversation.getTotalUnreadCount(
					false,
					conversationTypes
				).then((totalUnreadCount) => {
					// console.log("获取未读总数成功,组件里的", totalUnreadCount);
				});
			},
			//格式化时间
			formattingTime(time) {
				let date = new Date(time);
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();
				let hour = date.getHours();
				let minute = date.getMinutes();
				let second = date.getSeconds();
				month = month > 9 ? month : "0" + month;
				day = day > 9 ? day : "0" + day;
				hour = hour > 9 ? hour : "0" + hour;
				minute = minute > 9 ? minute : "0" + minute;
				second = second > 9 ? second : "0" + second;
				return (
					year +
					"-" +
					month +
					"-" +
					day +
					" " +
					hour +
					":" +
					minute +
					":" +
					second
				);
			},
			//清除指定用户的未读数 聊天记录点击这个用户 就应该清除未读
			clearTargetNoReadNum(val) {
				// 清除未读数
				let conversation = this.RongIMClient.Conversation.get({
					targetId: val.targetId,
					type: RongIMLib.CONVERSATION_TYPE.PRIVATE,
				});
				conversation.read().then(() => {
					// console.log("清除指定会话未读数成功");
					// this.$store.dispatch("changeMsgNum",this)
					val.unreadMessageCount = 0;
					// this.computedListNoRead()
					this.$store.dispatch("changeMsgNum", this);
					// 发送同步消息
					conversation.send({
						messageType: "RC:SRSMsg",
						content: {
							//从消息里获取服务器端时间，以最近一条已读 message 为准
							// lastMessageSendTime: message.sentTime,
						},
					}).then((message) => {
						// console.log("发送同步消息成功", message);
					});
				});
			},
			// 查看对应用户聊天记录
			lookChatRecords(val) {
				val.nickName = val.infoData.nickName;
				val.memberId = val.targetId;
				val.avatar = val.infoData.avatar;
				val.from = "person";
				localStorage.setItem("chatRecords", JSON.stringify(val));
				// this.current = index;
				this.isShow = false;
				if (this.$refs.SpiderWindowMessagebox) {
					this.$nextTick(() => {
						this.$refs.SpiderWindowMessagebox.init(val);
					});
				} else {
					setTimeout(() => {
						this.$nextTick(() => {
							this.$refs.SpiderWindowMessagebox.init(val);
						});
					}, 600);
				}
				this.clearTargetNoReadNum(val);
			},
			//监听
			watchM() {
				this.RongIMClient.watch({
					// 监听会话列表变更事件, 触发时机：会话状态变化（置顶、免打扰）、会话未读数变化（未读数增加、未读数清空）、会话 @ 信息、会话最后一条消息变化
					conversation: (event) => {
						// console.log("event更新的会话列表", event.updatedConversationList);
						// 假定存在 getExistedConversationList 方法，以获取当前已存在的会话列表数据
						// const conversationList = getExistedConversationList();
						// 发生变更的会话列表
						// const updatedConversationList = event.updatedConversationList;
						// 通过 im.Conversation.merge 计算最新的会话列表
						// const latestConversationList = _this.RongIMClient.Conversation.merge({
						//   conversationList,
						//   updatedConversationList,
						// });
					},
					// 监听消息通知
					message: (event) => {
						// 新接收到的消息内容
						const message = event.message;
						// console.log("接收的信息", this.receiveMsg);
						if (event.message.content.content) {
							this.receiveMsg.push(Object.assign(message, this.val));
							this.$store.commit("changeMsgList");
						}
						if (event.message.senderUserId != this.val.memberId) {
							return;
						}
						this.allMsgArr.push(message);
						// console.log("看下消息",this.allMsgArr)
						this.$store.commit("setSendMsgArr", this.receiveMsg); //放进vuex中 私信列表从vuex中取
					},
					// 监听 IM 连接状态变化
					status: (event) => {
						// console.log("connection status:", event);
						if (event.status == 1) {
							// console.log("链接中");
						}
						if (event.status == 0) {
							// console.log("链接成功");
							this.getMessageList();
						}
					},
					// 监听聊天室 KV 数据变更
					chatroom(event) {
						/**
						 * 聊天室 KV 存储数据更新
						 * @example
						 * [
						 *  {
						 *    "key": "name",
						 *    "value": "我是小融融",
						 *    "timestamp": 1597591258338,
						 *    "chatroomId": "z002",
						 *    "type": 1 // 1: 更新（ 含:修改和新增 ）、2: 删除
						 *  },
						 * ]
						 */
						const updatedEntries = event.updatedEntries;
					},
					expansion(event) {
						/**
						 * 更新的消息拓展数据
						 * @example {
						 *    expansion: { key: 'value' },      // 设置或更新的扩展值
						 *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新扩展的消息 uid
						 * }
						 */
						const updatedExpansion = event.updatedExpansion;
						/**
						 * 删除的消息拓展数据
						 * @example {
						 *    deletedKeys: ['key1', 'key2'],    // 设置或更新的扩展值
						 *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新扩展的消息 uid
						 * }
						 */
						const deletedExpansion = event.deletedExpansion;
					},
					// 监听离线消息拉取完成
					pullFinished() {
						// console.log("拉取离线消息完成");
					},
				});
			},
		},
	};
</script>

<style scoped>
	.index {
		display: flex;
		flex: 1;
	}

	.chat-list {
		width: 253px;
		user-select: none;
		cursor: pointer;
		height: 100%;
		background: #ffffff;
	}

	.chat-list .chat-list-user {
		height: calc(100% - 130px);
		/* height: 473px; */
		overflow-x: hidden;
		overflow-y: auto;
	}

	.nav-title {
		height: 60px;
		line-height: 60px;
		font-size: 18px;
		letter-spacing: 2px;
		padding: 10px;
		display: flex;
		align-items: center;
		background: #f5f7fa;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
	}

	.chat-list-user .user-info {
		height: 67px;
		display: flex;
		align-items: center;
		padding: 0 12px;
		border: 1px solid #dcdfe6;
		box-sizing: border-box;
	}

	.user-info:hover {
		background: #f5f7fa;
	}

	.active-background {
		height: 67px;
		display: flex;
		align-items: center;
		background: #f5f7fa;
		padding: 0 12px;
		border: 1px solid #dcdfe6;
		box-sizing: border-box;
	}

	.chat-list-user .user-icon {
		position: relative;
		flex-shrink: 0;
	}

	.chat-list-user .user-icon img {
		width: 42px;
		height: 42px;
		border-radius: 50%;
		margin-right: 8px;
	}

	.user-icon .badge {
		position: absolute;
		width: 16px;
		height: 16px;
		line-height: 16px;
		font-size: 12px;
		color: #ffffff;
		text-align: center;
		background: #ff6632;
		border-radius: 50%;
		top: 0;
		right: 2px;
	}

	.user-icon .badge2 {
		position: absolute;
		width: 21px;
		height: 16px;
		line-height: 16px;
		font-size: 12px;
		color: #ffffff;
		text-align: center;
		background: #ff6632;
		border-radius: 8px;
		top: 0;
		right: 2px;
	}

	.chat-list-user .user-right {
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}

	.user-right div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	.user-right div p {
		font-size: 14px;
		color: #313233;
	}

	.user-right div p:nth-child(2) {
		font-size: 12px;
		color: #abaeb3;
	}

	.default {
		min-width: 506px;
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: space-around;
		background: #f5f7fa;
		border-left: 1px solid #dcdfe6;
	}

	.default img {
		width: 100px;
		height: 100px;
	}

	.msg-box {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.user_name {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>